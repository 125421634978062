import { defineStore } from "./store.pinia";
import { api } from "../utils/feathers-api";

const servicePath = "iip-type";
const useIipType = defineStore({
  idField: "id",
  clients: { api },
  servicePath,
  getters: {
    byId: (state) => (iipTypeId) => {
      return state.items.filter((item) => item.id === iipTypeId);
    },
  },
});

api.service(servicePath).hooks({});

export { useIipType };
