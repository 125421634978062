<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :cols="12"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useGetHelper } from "../../utils/feathers-pinia/use-get-helpers";
import { useVendor } from "../../store/vendor.pinia";
import { useIipType } from "../../store/iipType.pinia";
import { useFind } from "feathers-pinia";
import { usePartVendor } from "../../store/partVendor.pinia";
import { useFindByPartId } from "../../utils/feathers-pinia/use-get-helpers";
import { computedRef } from "../../utils/composables";
import { useManufacturer } from "../../store/manufacturer.pinia";
import { formatPrice } from "../../utils/composables";
import { sortBy } from "lodash-es";
import { isRegionUS } from "../../utils/variables";

export default {
  name: "PurchasingAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("PurchasingAccordion.vue");
    const vendorStore = useVendor();
    const iipTypeStore = useIipType();
    const partVendorStore = usePartVendor();
    const manufacturerStore = useManufacturer();

    const iipTypeId = computed(
      () => props?.node?.part?.iip?.iip_type_id ?? null
    );
    const { item: iipTypeResults, isPending: isPendingIipType } = useGetHelper(
      iipTypeId,
      iipTypeStore
    );

    const { items: partVendorResults, isPending: isPendingPartVendor } =
      useFindByPartId(props.node?.part?.id, partVendorStore);

    const vendorIds = computedRef(() => {
      return partVendorResults.value?.map((item) => item.vendor_id) ?? [];
    });
    const vendorQuery = computedRef(() => {
      return {
        query: {
          id: { $in: vendorIds.value },
          $sort: { name: 1 },
        },
      };
    });
    const { items: vendorResults, isPending: isPendingVendor } = useFind({
      model: vendorStore.Model,
      params: vendorQuery,
      queryWhen: computed(() => !!vendorQuery.value?.query.id.$in.length),
    });

    const tier2Ids = computedRef(() => {
      return partVendorResults.value?.map((item) => item.tier2) ?? [];
    });
    const tier2Query = computedRef(() => {
      return {
        query: {
          id: { $in: tier2Ids.value },
          $sort: { name: 1 },
        },
      };
    });
    const { items: tier2Results } = useFind({
      model: manufacturerStore.Model,
      params: tier2Query,
      queryWhen: computed(() => !!tier2Query.value?.query.id.$in.length),
    });

    const tier3Ids = computedRef(() => {
      return partVendorResults.value?.map((item) => item.tier3) ?? [];
    });
    const tier3Query = computedRef(() => {
      return {
        query: {
          id: { $in: tier3Ids.value },
          $sort: { name: 1 },
        },
      };
    });
    const { items: tier3Results } = useFind({
      model: manufacturerStore.Model,
      params: tier3Query,
      queryWhen: computed(() => !!tier3Query.value?.query.id.$in.length),
    });

    const concatBuyer = computed(() => {
      return {
        name:
          props?.node?.part?.buyer?.first_name +
          " " +
          props?.node?.part?.buyer?.last_name,
      };
    });

    const sortedFormattedPartVendorResults = computed(() => {
      // format the item price, then sort by rank
      return sortBy(
        partVendorResults.value.map((item) => {
          return {
            ...item,
            price: formatPrice(item.price),
          };
        }),
        ["rank"]
      );
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        partVendor: isPendingPartVendor.value,
        buyer: props.isPending.part,
        iip: props.isPending.part,
        iipType: isPendingIipType.value,
        vendor: isPendingPartVendor.value,
        vendorsArray: isPendingVendor.value,
        tier2Array: isPendingPartVendor.value,
        tier3Array: isPendingPartVendor.value,
        arrayItem: isPendingPartVendor.value || isPendingVendor.value,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        partVendor: sortedFormattedPartVendorResults.value,
        buyer: concatBuyer.value,
        iip: props.node.part.iip,
        iipType: iipTypeResults.value,
        vendor: vendorResults.value[0],
        vendorsArray: vendorResults.value,
        tier2Array: tier2Results.value,
        tier3Array: tier3Results.value,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow(
          "Part Purchasing",
          [
            entityRowField("vendor", "name", {
              label: "Vendor Name",
              colAttributes: {
                cols: 12,
                sm: 6,
                class: "px-2",
              },
            }),
            entityRowField("buyer", "name", {
              label: "Buyer",
              colAttributes: {
                cols: 12,
                sm: 6,
                class: "px-2",
              },
            }),
            entityRowField("part", "purchasing_spec", {
              label: "Purchasing Spec",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            isRegionUS
              ? entityRowField("iip", "description", {
                  label: "iip Description",
                  colAttributes: {
                    cols: 12,
                    sm: 6,
                    class: "px-2",
                  },
                })
              : null,
            isRegionUS
              ? entityRowField("iipType", "type_desc", {
                  label: "iip Type",
                  colAttributes: {
                    cols: 12,
                    sm: 6,
                    class: "px-2",
                  },
                })
              : null,
            isRegionUS
              ? entityRowField("iip", "action_text", {
                  label: "iip Action Text",
                  colAttributes: {
                    cols: 12,
                    sm: 12,
                    class: "px-2",
                  },
                })
              : null,
            isRegionUS
              ? entityRowField("part", "iip_additional_text", {
                  label: "iip Additional Text",
                  colAttributes: {
                    cols: 12,
                    sm: 12,
                    class: "px-2",
                  },
                })
              : null,
            !isRegionUS
              ? entityRowField("part", "iip", {
                  label: "IIP",
                  colAttributes: {
                    cols: 12,
                    sm: 12,
                    class: "px-2",
                  },
                })
              : null,
            entityRowField("part", "purchasing_notes", {
              label: "Purchasing Notes",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            entityRowField("part", "receiving_notes", {
              label: "Receiving Notes",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            entityRowField("part", "tooling_notes", {
              label: "Tooling Notes",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
            entityRowField("part", "purchasing_alert", {
              label: "Purchase Alert",
              type: "textarea",
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
          ].filter((item) => item !== null)
        ),
        entityRow(
          "Vendors",
          [
            entityRowField("arrayItem", "rank", { label: "Vendor Rank" }),
            entityRowField(
              (rowProps) => {
                return rowProps.entities.vendorsArray.find(
                  (item) => item.id === rowProps.arrayItem.vendor_id
                );
              },
              "name",
              {
                label: "Vendor Name",
              }
            ),
            entityRowField(
              (rowProps) => {
                return rowProps.entities.tier2Array.find(
                  (item) => item.id === rowProps.arrayItem.tier2
                );
              },
              "name",
              {
                label: "Tier 2 Vendor",
              }
            ),
            entityRowField(
              (rowProps) => {
                return rowProps.entities.tier3Array.find(
                  (item) => item.id === rowProps.arrayItem.tier3
                );
              },
              "name",
              {
                label: "Tier 3 Vendor",
              }
            ),
            // entityRowField("partVendorManufacturer", "name", {
            //   label: "Part Vendor Manufacturer Name",
            // }),
            // need to show in currency format
            entityRowField("arrayItem", "price", {
              label: "Vendor Price",
            }),
          ],
          {
            rowType: "array",
            arrayPath: "partVendor",
            showLabel: true,
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
